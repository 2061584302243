// react
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";

// plugin
import { useHistory } from "react-router-dom";

// component
import CommonButton from "../../component/common-button/CommonButton";

//helpers
import { loginRsa } from "../login-layout/authentication";
import { rsaEnc } from "../../common/encode-decode";
import { sendOTPapiCall, verifyOTPapiCall } from "../../common/connect";
import { sendOTP, verifyOTP } from "../../common/urls";
import { ACCESS_TOKEN, POST } from "../../common/constants";

// style
import "../../layouts/login-layout/login.css";
import passwordhide from "../../assets/images/hide-eye.svg";
import passwordshow from "../../assets/images/show-eye.svg";
import satsureGreyLogo from "../../assets/images/satsure-grey.png";
import leftArrow from "../../assets/images/Internal-dashboard/Left.svg";
import { Stack } from "@mui/material";

const ForgotPasswordLayout = (props) => {
  let history = useHistory();
  const [emailid, setEmailid] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [isOTPRequested, setIsOTPRequested] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [isOTPVerifyDisabled, setIsOTPVerifyDisabled] = useState(true);
  const [otp, setOTP] = useState("");
  const [isInvaliemail, setIsInvalidemail] = useState("");
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [hideShowOTP, sethideShowOTP] = useState(true);
  // state to store the rsa key
  const [rsaKey, setRsaKey] = useState("");

  // calling the rsa token api here
  useEffect(() => {
    loginRsa(successLoginRsaCallBack, failureLoginRsaCallBack);
  }, []);

  useEffect(() => {
    setMinutes(4);
    setSeconds(59);
  }, [isOTPRequested]);

  // success and failure callbacks for the rsa login api
  const successLoginRsaCallBack = (response) => {
    setRsaKey(response.data.rsa);
  };
  const failureLoginRsaCallBack = (response) => {
    console.log(response);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    if (minutes == 0 && seconds == 0) {
      setIsOTPVerifyDisabled(true);
    }
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
  }

  function handleEmailId(event, value) {
    setEmailid(value.replace(/\s/g, ""));
    let filter = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (value.trim() === "") {
      setDisableButton(true);
    } else {
      if (filter.test(value)) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }

  function sendOTPhelper(payload) {
    const url = sendOTP();

    const onSuccess = (response) => {
      setLoginLoader(false);
      setEmailErrorMessage("");
      if (disableButton) {
        document.getElementById("continue-btn-disabled").style.marginBottom =
          "11.4rem";
      } else {
        document.getElementById("continue-btn").style.marginBottom = "11.4rem";
      }
      setIsOTPRequested(true);
    };
    const onFailure = (response) => {
      console.log(response);
      setEmailErrorMessage(response.message);
      if (disableButton) {
        document.getElementById("continue-btn-disabled").style.marginBottom =
          "10.3rem";
      } else {
        document.getElementById("continue-btn").style.marginBottom = "10.3rem";
      }

      setLoginLoader(false);
    };
    sendOTPapiCall(POST, url, payload, onSuccess, onFailure);
  }
  function verifyOTPhelper(payload) {
    const url = verifyOTP();

    const onSuccess = (response) => {
      setLoginLoader(false);
      history.push({
        pathname: "/resetPassword",
        state: response.data["api-key"],
      });
    };
    const onFailure = (response) => {
      console.log(response);
      document.getElementById("invalidOTPMessage").style.visibility = "visible";
      setLoginLoader(false);
    };
    verifyOTPapiCall(POST, url, payload, onSuccess, onFailure);
  }
  function triggerSendOTP() {
    var encryptedEmail = {
      email: rsaEnc(emailid.toLowerCase(), rsaKey), //encrypting the email id
    };
    sendOTPhelper(encryptedEmail);
    setLoginLoader(true);
  }

  function triggerVerifyOTP() {
    var encryptedOTP = {
      email: rsaEnc(emailid.toLowerCase(), rsaKey), //encrypting the email id
      otp: rsaEnc(otp, rsaKey), //encrypting the email id
    };
    verifyOTPhelper(encryptedOTP);
    setLoginLoader(true);
  }
  return (
    <Stack className="login-sections">
      <img
        src={leftArrow}
        alt="Left Arrow"
        onClick={() => history.push("/")}
        className="back-arrow-styles"
      />
      <div className={isOTPRequested ? "otp-heading" : "login-heading"}>
        <p>Forgot Password?</p>
      </div>
      {isOTPRequested ? (
        <div className="forgot-passwor-sections">
          <p
            style={{
              fontWeight: "400",
              fontSize: "13px",
              fontStyle: "italic",
              color: "#95969D",
              paddingBottom: "1rem",
            }}
          >
            Please check your email for the OTP
          </p>
          <ul className="forgot-password-field">
            <li>
              <div className="form-group">
                <label className="group-title">OTP</label>
                <span className="input-feild">
                  <input
                    type={hideShowOTP ? "password" : "text"}
                    className={"input-text "}
                    pattern="[0-9]+"
                    placeholder="Enter OTP"
                    name="password"
                    value={otp}
                    autocomplete="off"
                    onKeyDown={(e) => {
                      //detecting ctrl
                      var isCtrlPressed = e.ctrlKey
                        ? e.ctrlKey
                        : e.key === "Control" || e.key === "Command"
                          ? true
                          : false;

                      let key = Number(e.key);
                      if (!((key >= 0 && key <= 9) || e.key === "Backspace")) {
                        if (
                          !((e.key === "V" || e.key === "v") && isCtrlPressed)
                        )
                          e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setOTP(e.target.value.replace(/\s/g, "")); //replacing the spaces with empty character
                      if (
                        e.target.value.length == 6 &&
                        containsOnlyNumbers(e.target.value)
                      ) {
                        setIsOTPVerifyDisabled(false);
                      } else {
                        setIsOTPVerifyDisabled(true);
                      }
                    }}
                  />
                  <img
                    src={hideShowOTP ? passwordhide : passwordshow}
                    alt="passwordLogo"
                    onClick={() => sethideShowOTP((prev) => !prev)}
                    className="passwordIcon"
                  />
                </span>
              </div>
            </li>

            {invalidMessage && (
              <li>
                <p
                  style={{
                    color: "red",
                    fontStyle: "italic",
                    fontSize: "14px",
                  }}
                >
                  Invalid Username and Password.
                </p>
              </li>
            )}
          </ul>
          <p id="invalidOTPMessage" style={{ visibility: "hidden" }}>
            Invalid OTP
          </p>
          <div>
            {isOTPVerifyDisabled ? (
              <div className={"continue-btn-disabled"}>
                <CommonButton
                  btnName={"Reset Password"}
                  btnLoader={loginLoader}
                />
              </div>
            ) : (
              <div className={"continue-btn"}>
                <CommonButton
                  btnName={"Reset Password"}
                  btnCallBack={(e) => {
                    triggerVerifyOTP();
                  }}
                  btnLoader={loginLoader}
                />
              </div>
            )}

            <div
              className="resend-otp"
              style={{ marginBottom: "5.7rem", flexDirection: "column" }}
            >
              {seconds > 0 || minutes > 0 ? (
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    color: "#808080",
                    paddingBottom: "0.5rem",
                    textAlign: "center",
                  }}
                >
                  OTP will expire in {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds} mins
                </p>
              ) : (
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    color: "#808080",
                    paddingBottom: "0.5rem",
                    textAlign: "center",
                  }}
                >
                  OTP Expired
                </p>
              )}
              {minutes.toString() === "2" ||
              minutes.toString() == "1" ||
              minutes.toString() == "0" ? (
                <p
                  style={{ textAlign: "center" }}
                  className="resend-otp-active"
                  onClick={(e) => {
                    if (otp.length == 6) {
                      setIsOTPVerifyDisabled(false);
                    }
                    setMinutes(4);
                    setSeconds(59);
                    triggerSendOTP();
                  }}
                >
                  Resend OTP
                </p>
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    color: "#808080",
                    cursor: "not-allowed",
                  }}
                >
                  Resend OTP
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="forgot-passwor-sections">
          <ul className="forgot-password-field">
            <li>
              <label>Email address</label>
              <span className="feild">
                <Form.Control
                  type="email"
                  placeholder="XXXX@gmail.com"
                  value={emailid}
                  onChange={(e) => handleEmailId(e, e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key == " ") {
                      e.preventDefault();
                    }
                    if (e.keyCode === 13) {
                      if (!disableButton) triggerSendOTP();
                    }
                  }}
                />
              </span>
            </li>

            <li>
              <p
                style={{
                  color: "red",
                  fontStyle: "italic",
                  fontSize: "14px",
                }}
              >
                {emailErrorMessage}
              </p>
            </li>
          </ul>
          {disableButton ? (
            <div
              className={"continue-btn-disabled"}
              id="continue-btn-disabled"
              style={{ marginBottom: "11.4rem " }}
            >
              <CommonButton btnName={"Continue"} btnLoader={loginLoader} />
            </div>
          ) : (
            <div
              className={"continue-btn"}
              id="continue-btn"
              style={{ marginBottom: "11.4rem " }}
            >
              <CommonButton
                btnName={"Continue"}
                btnCallBack={(e) => {
                  triggerSendOTP();
                }}
                btnLoader={loginLoader}
              />
            </div>
          )}
        </div>
      )}

      <div className="second-logo-div">
        <p>Powered By</p>
        <div className="satsure-logo-div">
          <img src={satsureGreyLogo} />
        </div>
      </div>
    </Stack>
  );
};

export default ForgotPasswordLayout;
